<template>
    <div class="billing-info-container flex-column jc-sb">
        <div class="flex-column">
           <p class="billing-header default-text">Items</p>
           <BillingDetailItem
             labelText="Property listing (7 Days Free) -"
             :labelSpanText="labelData"
             :labelSize="LABEL_SIZE_LARGE"
             :amount="getDefaultPropertyBillingAmount"
             amountTextSize="14"/>
            <div class="row">
              <BillingMembersCountComponent/>
            </div>
            <div class="row hide-border-in-mobile">
              <BillingStorageTypeComponent/>
              <div class="flex-row ai-c jc-sb">
               <p class="label default-text">Storage plan - <span>{{getStoragePlan.capacity}}</span> <span class="small-text">(included)</span></p>
               <p class="billing-amount default-text">
                {{$filters.formatToCurrency(getStoragePlan.monthly.toString())}}
               </p>
              </div>
            </div>
            <!-- <BillingPaymentMethodComponent class="hide-in-mobile"/> -->
        </div>
        <el-button type="primary" class="button active flex-row ai-c jc-c show-in-mobile" @click="onNextJourney" round>NEXT</el-button>
    </div>
</template>
<script>
// import { InfoFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import BillingDetailItem from '@/core/components/billing/BillingDetailItem.vue';
import { LABEL_SIZE_LARGE } from '@/core/constants/label';
import BillingMembersCountComponent from '@/modules/properties-billing/components/BillingMembersCountComponent.vue';
// import BillingPaymentMethodComponent from '@/modules/properties-billing/components/BillingPaymentMethodComponent.vue';
import BillingStorageTypeComponent from '@/modules/properties-billing/components/BillingStorageTypeComponent.vue';
import { BILLING_STORE } from '@/store/modules/billing';

export default defineComponent({
  components: {
    // InfoFilled,
    BillingMembersCountComponent,
    BillingStorageTypeComponent,
    // BillingPaymentMethodComponent,
    BillingDetailItem
  },
  props: ['labelData'],
  emits: ['go-to-payment-method'],
  data() {
    return {
      LABEL_SIZE_LARGE
    };
  },
  setup(props, { emit }) {
    function onNextJourney() {
      emit('go-to-payment-method', true);
    }

    return {
      onNextJourney
    };
  },
  computed: {
    ...mapGetters(BILLING_STORE, ['getStoragePlan', 'getDefaultPropertyBillingAmount']),
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: left;
}

.billing-info-container {
  gap: 2rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(42, 42, 42, 0.02);
  border-radius: 8px;
  padding: 2.2rem 2rem;
  box-sizing: border-box;
}
.billing-header {
    font-size: 18px;
    line-height: 22px;
}
.row {
    width: 100%;
    padding-bottom: 1rem;
    border-bottom:  2px solid rgba(12, 15, 74, 0.1);
}
.label {
    span {
        color: #FFA500;
    }
    .small-text {
      font-size: 10px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0.25px;
      text-align: left;
      color: #0C0F4A;
    }
}
.button {
  height: 40px;
  padding: 0 2rem;
  box-sizing: border-box;
  background-color: rgba(12, 15, 74, 0.05);
  border: none;
  border-radius: 7px;
  @extend .default-text;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: rgba(12, 15, 74, 0.5);
  width: 100%;
}

.active {
  background-color: #FFA500;
  color: #fff;
}
@include media-sm-max-width() {
   .billing-info-container {
       height: 100%;
       border-radius: 0;
       border-top-left-radius: 35px;
       border-top-right-radius: 35px;
   }
   .row.hide-border-in-mobile {
       border-bottom: none;
   }
}
@include media-xs-max-width() {
    .label, .billing-amount {
        font-size: 12px;
    }
}
</style>
