<template>
    <div class="billing-summary-container flex-column jc-sb">
        <BillingPaymentMethodComponent class="show-in-mobile" @go-to-payment-plan="onBackJourney"/>
        <div class="flex-column">
          <p class="billing-summary-header default-text hide-in-mobile">Total</p>
          <div class="row flex-column">
            <div class="col flex-row ai-c jc-sb">
                <p class="label default-text">1st property listing</p>
                <p class="amount default-text">{{ $filters.formatToCurrency(getDefaultPropertyBillingAmount) }}</p>
            </div>
            <div class="col flex-row ai-c jc-sb">
                <p class="label default-text">Members ({{getTotalMember + getAddedMember}}) seats</p>
                <p class="amount default-text">{{ $filters.formatToCurrency(totalMemberAmountFormula().toString()) }}</p>
            </div>
            <div class="col flex-row ai-c jc-sb">
                <p class="label default-text">Storage plan ({{getStoragePlan.capacity}})</p>
                <p class="amount default-text">{{$filters.formatToCurrency(getStoragePlan.monthly)}}</p>
            </div>
          </div>
          <div class="row flex-column">
            <div class="col flex-row ai-c jc-sb">
                <p class="label default-text">Sub total</p>
                <p class="amount default-text">{{ $filters.formatToCurrency(getSubtotal) }}</p>
            </div>
            <div class="col flex-row ai-c jc-sb">
                <p class="label default-text">VAT (20%)</p>
                <p class="amount default-text">{{  $filters.formatToCurrency(getVat) }}</p>
            </div>
          </div>
          <div class="last-row flex-column">
            <div class="col flex-row ai-c jc-sb">
                <p class="label bold default-text">Due today</p>
                <!-- this is always 0.00, as first billing is always on trial -->
                <p class="amount bold default-text">{{ DEFAULT_VALUE }}</p>
            </div>
            <div class="col flex-row ai-c jc-sb">
                <p class="label gray default-text">Billed monthly after 7 days</p>
                <p class="amount gray default-text">{{ $filters.formatToCurrency(getBillMonthly) }}</p>
            </div>

           <el-button
              type="primary"
              class="button-cta"
              :class="{ 'yellow-button': getPaymentMethod }"
              @click="onPay()"
              :loading="isLoading"
              :disabled="isLoading || getPaymentMethod === ''" round>{{ isLoading ? 'PROCESSING...' : 'START FREE TRIAL' }}</el-button>

            <PaymentDescriptionComponent
              :hasTrialDays="true"
              :monthlyBill="getBillMonthly"/>
          </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { DEFAULT_VALUE } from '@/core/components/billing/constants';
import PaymentDescriptionComponent from '@/core/components/billing/PaymentDescriptionComponent.vue';
import StringHelper from '@/core/helpers/string.helper';
import $axios from '@/core/utils/axios-api-config';
import appFilters from '@/filters';
import BillingPaymentMethodComponent from '@/modules/properties-billing/components/BillingPaymentMethodComponent.vue';
import { BILLING_STORE } from '@/store/modules/billing';
import { PROPERTIES_STORE } from '@/store/modules/properties';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USERS_STORE } from '@/store/modules/users';

import { VUE_APP_STRIPE_PUBLISHABLE_KEY } from '../../../config';

function initializeStripe() {
  let stripe = null;

  if ('Stripe' in window) {
    const { Stripe } = window;

    // eslint-disable-next-line no-new
    stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
  }

  return stripe;
}

export default defineComponent({
  components: {
    BillingPaymentMethodComponent,
    PaymentDescriptionComponent
  },

  emits: ['go-to-payment-plan'],

  data() {
    return {
      isLoading: false,
      redirectUrl: '',
      DEFAULT_VALUE
    };
  },
  computed: {
    ...mapGetters(BILLING_STORE, [
      'getStoragePlan',
      'getTotalMember',
      'getPerMemberPrice',
      'getListingAmount',
      'getPaymentMethod',
      'getAddedMember',
      'getDefaultPropertyBillingAmount'
    ]),

    ...mapGetters(USERS_STORE, ['user']),

    ...mapGetters(PROPERTIES_STORE, ['defaultTrialSubscriptionInDays']),

    getSubtotal() {
      return this.subTotalFormula();
    },

    getDefaaultZeroBill() {
      return appFilters.removeCurrency(this.DEFAULT_VALUE);
    },

    getVat() {
      if (this.getSubtotal !== this.getDefaaultZeroBill) {
        return this.vatFormula();
      }
      return DEFAULT_VALUE;
    },

    getBillMonthly() {
      if (this.getSubtotal !== this.getDefaaultZeroBill) {
        return this.monthlyBillingFormula();
      }
      return this.getDefaaultZeroBill;
    }
  },

  async created() {
    const { params } = this.$route;
    const propertyId = params.id;

    const host = `${window.location.protocol}//${window.location.host}`;
    this.redirectUrl = `${host}/properties/${propertyId}/property-details/home`;

    if (propertyId) {
      this.property = await this.getProperty(propertyId);
      this.setDefaultTrialDayse(this.defaultTrialSubscriptionInDays);
    }

    await this.initializeUserState();
  },

  methods: {
    ...mapActions(PROPERTY_STORE, ['getProperty']),

    ...mapActions(USERS_STORE, ['initializeUserState']),

    ...mapActions(BILLING_STORE, ['setDefaultTrialDayse']),

    totalMemberAmountFormula() {
      return this.getPerMemberPrice * this.getAddedMember;
    },
    subTotalFormula() {
      return this.totalMemberAmountFormula()
        + this.getStoragePlan.monthly + this.getListingAmount + parseFloat(this.getDefaultPropertyBillingAmount);
    },
    vatFormula() {
      return this.subTotalFormula() * 0.20;
    },
    monthlyBillingFormula() {
      return this.subTotalFormula() + this.vatFormula();
    },
    routeToPropertyDashboard() {
      const { params } = this.$route;
      this.$router.push(`/properties/${params.id}/property-details/home`);
    },
    onBackJourney() {
      this.$emit('go-to-payment-plan', false);
    },

    async onPay() {
      this.isLoading = true;

      setTimeout(() => {
        this.initializeStripePayment();
      }, 500);
    },

    async initializeStripePayment() {
      this.stripe = initializeStripe();

      if (this.stripe) {
        await this.onCheckout();
      }
    },

    async createSession() {
      const { id: userId, userStripeCustomer, email } = this.user;
      const { params } = this.$route;
      const propertyId = params.id;

      let customer = { customerId: '' };
      let productName = '';

      const entity = JSON.stringify({ id: propertyId, name: 'property' });

      const { id, uprn } = this.property;

      productName = `property-${id}`;

      if (uprn) {
        productName = `${productName}-${uprn}`;
      }

      if (!userStripeCustomer.length) {
        // tmp, needs to be transferred to service
        await $axios.post(`/payment/${userId}/customer`, {
          email: StringHelper.removeEmailAlias(email),
          entity
        }).then((customerResponse) => {
          customer = customerResponse;
        })
          .catch((e) => {
            this.$notify.error({
              message: e || 'Error occured, please try again'
            });
          });
      } else {
        const [stripeCustomer] = userStripeCustomer; // 1-1 customer only for now
        customer = stripeCustomer;
      }

      const { customerId } = customer;
      const totalPrice = this.getBillMonthly;

      if (customerId) {
        await $axios.post(`/payment/${userId}/session`, {
          customerId,
          price: parseFloat(totalPrice),
          redirectUrl: this.redirectUrl,
          productName,
          metadata: JSON.stringify({
            ...this.getStoragePlan,
            totalMember: this.getAddedMember
          }),
          isTrial: true,
          trialPeriod: parseInt(this.defaultTrialSubscriptionInDays, 10)
        }).then(async (sessionResponse) => {
          if (sessionResponse) {
            this.sessionId = sessionResponse.id;
          }
        });
      }
    },

    async onCheckout() {
      await this.createSession();

      if (this.sessionId) {
        this.stripe.redirectToCheckout({
          sessionId: this.sessionId,
        }).then((result) => {
          if (result.error) {
            this.$notify.error({
              message: result.error || 'Error.'
            });
          }
        });
      }
    },

  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
}
.billing-summary-container {
    gap: 1rem;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 10px rgba(42, 42, 42, 0.02);
    border-radius: 8px;
    padding: 2.2rem 2rem;
    box-sizing: border-box;
    // height: 476px;
    margin-bottom: 2rem;
}
.billing-summary-header {
    font-size: 18px;
    font-weight: 700;
}
.row {
    width: 100%;
    padding-bottom: 1rem;
    border-bottom:  2px solid rgba(12, 15, 74, 0.1);
    gap: .5rem;
}
.pb4 {
    padding-bottom: 4rem;
}
.amount {
    font-weight: 700;
}
.bold {
    font-size: 14px;
    font-weight: 700;
}
.gray {
    color: rgba(12, 15, 74, 0.5);
}
.reminder {
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}
.button-cta {
    height: 42px;
    border-radius: 8px;
    background: rgba(12, 15, 74, 0.1);
    font-family: Mulish;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    color: rgba(12, 15, 74, 0.5);
    font-family: Mulish;
    border: none;

    &:hover {
        background: rgba(12, 15, 74, 0.1);
        color: rgba(12, 15, 74, 0.5);
    }
}

.button-cta.yellow-button {
  width: 100%;
  border-radius: 7px;
  background: #FAA200;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  letter-spacing: 1.25px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.active {
    background: #000000;
    color: #FFFFFF;

    :deep(span) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .7rem;
    }

    &:hover {
        background: #000000;
        color: #FFFFFF;
    }
}

@include media-sm-max-width() {
   .billing-summary-container {
       height: 100%;
       border-radius: 0;
       border-top-left-radius: 35px;
       border-top-right-radius: 35px;
       margin-bottom: 0;
       background: #FFF;
       height: auto;
   }
   .pb4 {
     padding-bottom: 1rem;
   }
}
</style>
