<template>
    <div class="payment-description flex-row ai-c jc-c">
        <p class="payment-description__label default-text" style="text-align: center;" v-if="hasTrialDays">
          After your {{getDefaultTrialDays}} day trial ends, you will be charged {{$filters.formatToCurrency(monthlyBillTotal)}}
         <br> monthly, starting <span>{{getBillingMonth}} {{getBillingDay}}, {{getBillingYear}}</span>. Cancel anytime.
        </p>
        <p class="payment-description__label default-text" style="text-align: center;" v-else>
          You will be charged {{monthlyBill}} every 30 days, starting <span>{{getBillingMonth}} {{getBillingDay}}, {{getBillingYear}}</span>. Cancel anytime.
        </p>
    </div>
</template>
<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { BILLING_STORE } from '@/store/modules/billing';

export default defineComponent({
  props: {
    monthlyBill: {
      type: String,
      default: '0.00'
    },
    hasTrialDays: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      monthlyBillTotal: ''
    };
  },

  watch: {
    monthlyBill: {
      immediate: true,
      deep: true,
      handler(value) {
        this.setMonthlyBillTotal(value);
      }
    }
  },

  computed: {
    ...mapGetters(BILLING_STORE, ['getDefaultTrialDays']),

    startBillingDate() {
      return moment(new Date()).add(this.hasTrialDays ? Number(this.getDefaultTrialDays) : 0, 'days');
    },
    getBillingMonth() {
      return moment(this.startBillingDate).format('MMMM');
    },
    getBillingYear() {
      return moment(this.startBillingDate).format('yyyy');
    },
    getBillingDay() {
      return moment(this.startBillingDate).format('D');
    }
  },

  methods: {
    setMonthlyBillTotal(value) {
      this.monthlyBillTotal = value;
    },

  }
});
</script>
<style lang="scss" scoped>
  .payment-description {
      &__label {
        font-size: 10px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: rgba(12, 15, 74, 0.5);

        span {
          font-size: 11px;
          font-weight: 700;
        }
      }
  }
</style>
