<template>
    <div class="billing-detail-item flex-row ai-c jc-sb">
      <p class="billing-detail-item__label default-text"
        :class="{
          'sm': labelSize === LABEL_SIZE_SMALL,
          'm': labelSize === LABEL_SIZE_MEDIUM,
          'lg': labelSize === LABEL_SIZE_LARGE,
          'xlg': labelSize === LABEL_SIZE_XLARGE
        }">
          {{ labelText }} <span v-if="labelSpanText">{{ labelSpanText }}</span>
      </p>
      <p class="billing-detail-item__amount default-text">{{ $filters.formatToCurrency(amount) }}</p>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import { DEFAULT_VALUE } from '@/core/components/billing/constants';
import {
  LABEL_SIZE_LARGE, LABEL_SIZE_MEDIUM, LABEL_SIZE_SMALL, LABEL_SIZE_XLARGE
} from '@/core/constants/label';

export default defineComponent({
  props: {
    labelText: {
      type: String,
      defualt: 'N/A',
    },
    labelSize: {
      type: String,
      default: LABEL_SIZE_SMALL
    },
    labelSpanText: {
      type: String,
    },
    color: {
      type: String,
      default: '#0C0F4A'
    },
    amountTextSize: {
      type: String,
      default: '12px'
    },
    amount: {
      type: String,
    }
  },
  data() {
    return {
      DEFAULT_VALUE,
      LABEL_SIZE_SMALL,
      LABEL_SIZE_MEDIUM,
      LABEL_SIZE_LARGE,
      LABEL_SIZE_XLARGE
    };
  },
});
</script>
<style lang="scss" scoped>
.billing-detail-item {
   &__label, &__amount {
     color: v-bind('$props.color');
     line-height: 22px;
     letter-spacing: 0.25px;
   }
   &__label {
     span {
        font-size: 12px;
        font-weight: 400;
     }
   }
   &__label.sm {
        font-size: 12px;
        font-weight: 400;
   }
   &__label.m {
        font-size: 14px;
        font-weight: 700;
   }
   &__label.lg {
        font-size: 16px;
        font-weight: 700;
   }
   &__label.xlg {
        font-size: 18px;
        font-weight: 700;
   }
   &__amount {
        font-size: v-bind('$props.amountTextSize');
        font-weight: 700;
   }
}
</style>
