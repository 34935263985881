<template>
    <div class="default-grid-layout">
      <div class="default-grid-layout__wrapper grid">
        <slot></slot>
      </div>
    </div>
</template>
<script>

export default {
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.grid {
    display: grid;
}
.default-grid-layout {
    max-width: 1250px;
    height: calc(100% - 4.5rem);
    margin-left: 10px;
     margin-right: 20px;
    gap: 1rem;

    &__wrapper {
      grid-template-columns: 2fr 1fr;
    }
}

@media screen and (max-width: 1383px) {
    .default-grid-layout {
      &__wrapper {
        display: flex;
        flex-direction: column;
      }
    }
}

@include media-md-max-width()  {
  .default-grid-layout {
    margin: 0 25px;
  }
}
@include media-sm-max-width() {
  .default-grid-layout {
    width: 100%;
    margin: 0;
    height: 100%;

    // &__wrapper {
    //   height: 100%;
    // }
  }
}
// @include media-xs-max-width() {
//   .default-grid-layout {
//     &__wrapper {
//       height: auto;
//     }
//   }
// }
</style>
