<template>
    <div class="storage-item flex-column ai-c jc-se">
        <p class="tag default-text" v-if="plan.isMostPopular">MOST POPULAR</p>
        <p class="data default-text">{{plan.capacity}}</p>
        <p class="amount default-text">£{{plan.monthly}} <span>+ VAT</span></p>
        <p class="label default-text">monthly</p>
    </div>
</template>
<script>
export default {
  props: ['plan']
};
</script>
<style lang="scss" scoped>
.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.flex-row {
    display: flex;
    gap: 1rem;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.ai-c {
    align-items: center;
}
.jc-se {
    justify-content: space-evenly;
}
.storage-item {
    width: 124px;
    height: 100px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.1);
    border-radius: 8px;
    padding: 1.2rem 0;
    gap: 0;
    cursor: pointer;
    position: relative;
    border: 2px solid transparent;
    min-width: 124px;
}
.data {
    font-size: 18px;
    font-weight: 700;
    line-height: 0;
    text-align: center;
    color: #FFA500;
}
.amount {
    font-size: 14px;
    font-weight: 400;
    line-height: 0;
    letter-spacing: 0.15000000596046448px;

    span {
        font-size: 12px;
        line-height: 0;
        letter-spacing: 0.4000000059604645px;
        color: rgba(12, 15, 74, 0.5);
    }
}
.label {
    font-size: 10px;
    font-weight: 400;
    line-height: 0;
    letter-spacing: 0.4000000059604645px;
    color: rgba(12, 15, 74, 0.5);
}
.tag {
    padding: .2rem .4rem;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    color: #FFFFFF;
    background-color: #FFA500;
    box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.1);
    border-radius: 3px;
    position: absolute;
    top: -10px;
    right: 6px;
}
</style>
