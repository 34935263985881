<template>
    <div class="storage-type-container flex-column">
        <el-icon class="cancel" @click="cancel" v-if="activeItem !== null" :size="25" color="#D23232"><CircleCloseFilled/></el-icon>
        <div class="row flex-row ai-c">
           <p class="default-text">Storage</p>
           <el-icon :size="17" color="rgba(12, 15, 74, 0.3)"><InfoFilled/></el-icon>
        </div>
        <div class="storage flex-row">
          <StorageItemComponent v-for="(plan, index) in storagePlans" :key="index" :plan="plan" :class="{'active': plan.planType == activeItem}" @click="choosePlan(plan)"/>
        </div>
    </div>
</template>
<script>
import { CircleCloseFilled, InfoFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import StorageItemComponent from '@/modules/properties-billing/components/StorageItemComponent.vue';
import { BILLING_STORE } from '@/store/modules/billing';

export default defineComponent({
  components: {
    InfoFilled,
    StorageItemComponent,
    CircleCloseFilled
  },

  emits: ['on-select-plan'],

  props: ['plan'],

  data() {
    return {
      storagePlans: [
        {
          planType: 1,
          capacity: '10GB',
          monthly: 0.99,
          isMostPopular: false,
        },
        {
          planType: 2,
          capacity: '50GB',
          monthly: 1.49,
          isMostPopular: false,
        },
        {
          planType: 3,
          capacity: '100GB',
          monthly: 2.49,
          isMostPopular: true,
        },
        {
          planType: 4,
          capacity: '250GB',
          monthly: 3.49,
          isMostPopular: false,
        },
        {
          planType: 5,
          capacity: '500GB',
          monthly: 4.49,
          isMostPopular: false,
        },
        {
          planType: 6,
          capacity: '1TB',
          monthly: 5.49,
          isMostPopular: false,
        }
      ],
      activeItem: null,
    };
  },
  computed: {
    ...mapGetters(BILLING_STORE, ['getStoragePlan']),
  },

  created() {
    if (this.$props?.plan) {
      this.choosePlan(this.$props?.plan);
    }
  },

  methods: {
    ...mapActions(BILLING_STORE, ['setStoragePlan']),

    choosePlan(plan) {
      this.activeItem = plan.planType;
      this.setStoragePlan(plan);

      this.$emit('on-select-plan');
    },
    cancel() {
      this.activeItem = null;
      this.setStoragePlan(
        {
          planType: 0,
          capacity: '1GB',
          monthly: 0.00,
          isMostPopular: false,
        }
      );
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.flex-row {
    display: flex;
    gap: 1rem;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.ai-c {
    align-items: center;
}
.storage-type-container {
    position: relative;
}
.storage {
    gap: .7rem;
    overflow-x: scroll;
    padding: 1rem;
}
.active {
  border-color: #FFA500;
  transition: all .3s ease-in-out;
}
.cancel {
    cursor: pointer;
    transition: all .3s ease-in-out;
    position: absolute;
    right: 0;
    top: -2px;
}
</style>
