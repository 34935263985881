<template>
    <DefaultGridLayoutSlot v-if="hasMainLayoutInitialized">
        <BillingInfoComponent :labelData="property" v-if="windowWidth <= 768 && !isInPaymentMethod || windowWidth > 768" @go-to-payment-method="onHandleJourney"/>
        <BillingSummaryComponent v-if="windowWidth <= 768 && isInPaymentMethod || windowWidth > 768" @go-to-payment-plan="onHandleJourney"/>
    </DefaultGridLayoutSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import DefaultGridLayoutSlot from '@/core/components/slots/DefaultGridLayoutSlot.vue';
import BillingInfoComponent from '@/modules/properties-billing/components/BillingInfoComponent.vue';
import BillingSummaryComponent from '@/modules/properties-billing/components/BillingSummaryComponent.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    BillingInfoComponent,
    BillingSummaryComponent,
    DefaultGridLayoutSlot
  },
  data() {
    return {
      isInPaymentMethod: false,
      windowWidth: window.innerWidth,

      property: null,
    };
  },
  watch: {
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
    },
    property: {
      immediate: true,
      deep: true,
      handler() {
        this.initialize();
      }
    },
  },
  async created() {
    await this.initialize();
  },
  computed: {
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapActions(PROPERTY_STORE, ['getProperty']),

    async initialize() {
      const { id } = this.$route.params;
      await this.getProperty(id).then((raw) => {
        const result = { ...raw, addressData: JSON.parse(raw.addressData) };

        this.property = result.addressData.ADDRESS;
      });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    onHandleJourney(event) {
      this.isInPaymentMethod = event;
    }
  }
});
</script>
<style lang="scss" scoped>
</style>
